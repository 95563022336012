import Bus from '../inc/bus';
import ObjectStore from '../inc/objectStore';

const STATUS_FAILED = 3,
	STATUS_INITIAL = 0,
	STATUS_SAVING = 1,
	STATUS_SUCCESS = 2;

export default {
	data() {
		return {
			uploadError: null,
			currentStatus: null,
			fileUrls: [],
			fileLimit: 1,
			allowOverwrite: false
		};
	},
	computed: {
		isInitial() {
			return this.currentStatus === STATUS_INITIAL;
		},
		isSaving() {
			return this.currentStatus === STATUS_SAVING;
		},
		isSuccess() {
			return this.currentStatus === STATUS_SUCCESS;
		},
		isFailed() {
			return this.currentStatus === STATUS_FAILED;
		}
	},
	created() {
		this.updateFileUrls();

		if(!this.block.content.files) {
			this.$set(this.block.content, 'files', []);
		}

		Bus.on('blocksaved', id => {
			if(id === this.block.id) {
				this.updateFileUrls();
			}
		});
	},
	methods: {
		reset() {
			this.currentStatus = STATUS_INITIAL;
			this.uploadError = null;
		},
		upload(file) {
			return ObjectStore.upload(file).then(uploaded => this.block.content.files.push(uploaded));
		},
		getAllowedFileTypes() {
			if(this.allowedFileTypes) {
				return this.allowedFileTypes;
			}

			const mimeTypes = [];

			if(this.acceptTypes) {
				for(const type of this.acceptTypes) {
					mimeTypes.push(...type.mimeTypes);
				}
			}

			return mimeTypes;
		},
		filesChange(fileList) {
			if(!fileList.length) {
				return;
			}

			if((fileList.length + this.block.content.files.length) > this.fileLimit) {
				if(this.allowOverwrite && (fileList.length <= this.fileLimit)) {
					this.block.content.files.splice(0, fileList.length);
				}
				else if(this.fileLimit === 1) {
					Bus.emit('error', 'You can only upload one file to this block.');

					return;
				}
				else {
					Bus.emit('error', `You can only upload a total of ${this.fileLimit} files to this block.`);

					return;
				}
			}

			this.currentStatus = STATUS_SAVING;
			const promises = [];
			const allowedFileTypes = this.getAllowedFileTypes();

			for(let i = 0; i < fileList.length; i++) {
				if(allowedFileTypes.includes('*') || allowedFileTypes.includes(fileList[i].type)) {
					promises.push(this.upload(fileList[i]));
				}
				else {
					Bus.emit('error', `File type not allowed: ${fileList[i].type}. Skipping...`);
				}
			}

			Promise.all(promises).then(() => {
				this.currentStatus = STATUS_SUCCESS;
				this.$emit('edit');
			}).catch(err => {
				this.uploadError = err.response;
				this.currentStatus = STATUS_FAILED;
			});
		},
		updateFileUrls() {
			this.fileUrls = [];

			if(this.block.content.files && this.block.content.files.length) {
				for(const file of this.block.content.files) {
					ObjectStore.getFileUrl(file).then(url => {
						this.fileUrls.push(url);
					}).catch(() => {
						this.fileUrls.push('#');
						Bus.emit('error', 'Unable to resolve fetch file URL.');
					});
				}
			}
		},
		removeFile(index) {
			this.block.content.files.splice(index, 1);
			this.$emit('edit');
		}
	}
};