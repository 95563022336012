<template>
	<div class="block-inner upload-block">
		<div v-if="isFailed">
			<h2>Upload failed.</h2>
			<pre>{{ uploadError }}</pre>
		</div>

		<DropZone v-else :max-files="fileLimit" :is-saving="isSaving" :accept-types="acceptTypes" @upload="filesChange" />

		<div v-if="block.content.files" class="current-file">
			<draggable tag="ul" :list="block.content.files" :group="{ name: 'files', pull: false, put: false }" @end="$emit('edit')">
				<li v-for="(file, index) in block.content.files" :key="file.file">
					<div class="inline-label">
						<span><IconMoveVertical :width="16" :height="16" /></span>
						<input v-model="file.name" type="text" @blur="$emit('edit')" />
					</div>
					<BaseButton type="primary rounded" @click="removeFile(index)">
						<IconCross :width="12" :height="12" />
					</BaseButton>
				</li>
			</draggable>
		</div>
	</div>
</template>

<script>
	import IconMoveVertical from '../icons/IconMoveVertical';
	import draggable from 'vuedraggable';
	import editorUploadMixin from '../../mixins/editorUploadMixin';
	import BaseButton from '../BaseButton';
	import IconCross from '../icons/IconCross';
	import DropZone from '../DropZone';

	export default {
		components: {
			DropZone,
			IconMoveVertical,
			draggable,
			BaseButton,
			IconCross
		},
		mixins: [editorUploadMixin],
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				acceptTypes: [
					{
						label: 'PDF',
						code: 'pdf',
						mimeTypes: ['application/pdf']
					},
					{
						label: 'Image',
						code: 'image',
						mimeTypes: [
							'image/jpeg',
							'image/png',
							'image/gif',
							'image/bmp'
						]
					}
				],
				fileLimit: 10
			};
		},
		mounted() {
			this.reset();
		}
	};
</script>

<style lang="scss" scoped>
	.current-file {
		flex: 1 1 0%;

		li {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			margin-bottom: $default_padding / 2;

			.button::v-deep {
				margin-left: 10px;

				button {
					background-color: $color__dark;
					padding: 5px;

					&:hover {
						background-color: rgba($color: $color__dark, $alpha: 0.8);
					}
				}
			}
		}
	}

	.dropzone::v-deep {
		margin-bottom: $default_padding;
	}

	.block-inner::v-deep {
		ul {
			list-style-type: none;
			padding-left: 0;

			a {
				text-decoration: none;
				color: $color__text;
			}
		}
	}

	.inline-label {
		flex: 1;
		margin-bottom: 0;

		span:first-child {
			cursor: grab;

			&:active {
				cursor: grabbing;
			}
		}
	}

	label {
		text-align: center;
		cursor: pointer;

		input {
			display: none;
		}
	}
</style>